import { createAsync, useSearchParams } from '@solidjs/router';
import { ActivityIndicator, Container, Heading, Page, Section } from '@troon/ui';
import { createContext, useContext, Show, For, Suspense } from 'solid-js';
import { gql, TroonRewardsAppStatus } from '../../../../graphql';
import { cachedQuery } from '../../../../graphql/cached-get';
import type { ParentProps } from 'solid-js';

type CTX = {
	facilityId: string;
	rewardsId: string;
	firstName: string;
	lastName: string;
	email: string;
	operatorId: string | undefined;
	operatorName: string | undefined;
};

const initialContext: CTX = {
	facilityId: '',
	rewardsId: '',
	firstName: '',
	lastName: '',
	email: '',
	operatorId: undefined,
	operatorName: undefined,
};

const Context = createContext<CTX>(initialContext);

export function GolferContext(props: ParentProps) {
	const [search] = useSearchParams();

	const golfer = createAsync(async () => {
		const rewardsId = Array.isArray(search.rewardsId)
			? search.rewardsId[search.rewardsId.length - 1]
			: (search.rewardsId ?? undefined);
		return await (rewardsId ? golferExists({ email: rewardsId as string }) : undefined);
	});

	const errors: Array<string> = [];
	const ctx = Object.keys(initialContext).reduce((memo, key) => {
		const value = search[key]?.toString();
		if (!value) {
			errors.push(`Missing value for ${key}.`);
		}
		memo[key as keyof CTX] = Array.isArray(value) ? value[value.length - 1] : (value ?? '');
		return memo;
	}, {} as CTX);

	return (
		<Show
			when={errors.length === 0}
			fallback={
				<FallbackContainer>
					<Heading as="h1">Error</Heading>
					<p>Something went wrong. Please go back to Troon Operator and click "Purchase Troon Access" again.</p>
					<ul class="text-sm">
						<For each={errors}>{(error) => <li>{error}</li>}</For>
					</ul>
				</FallbackContainer>
			}
		>
			<Suspense
				fallback={
					<FallbackContainer>
						<ActivityIndicator class="size-16 text-brand" />
					</FallbackContainer>
				}
			>
				<Show
					when={golfer()?.userExists.status !== TroonRewardsAppStatus.NoAccount}
					fallback={
						<FallbackContainer>
							<Heading as="h1">Error</Heading>
							<p>Rewards account does not exist</p>
						</FallbackContainer>
					}
				>
					<Context.Provider value={ctx}>{props.children}</Context.Provider>
				</Show>
			</Suspense>
		</Show>
	);
}

function FallbackContainer(props: ParentProps) {
	return (
		<Container size="small">
			<Page>
				<Section>{props.children}</Section>
			</Page>
		</Container>
	);
}

export function useGolfer() {
	return useContext(Context)!;
}

const golferExistsQuery = gql(`
	query golferExists($email: String!) {
		userExists(email: $email) { status }
	}`);

const golferExists = cachedQuery(golferExistsQuery, {});
